import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { Role } from "./core/models/role";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
const routes: Routes = [
  /* {
    path: "",
    children: [
      { path: "", redirectTo: "/web/home", pathMatch: "full" },
      {
        path: "web",
        loadChildren: () =>
          import("./web/web.module").then((m) => m.WebModule),
      },
    ],
  }, */
  {
    path: "",
    children: [
      { path: "", redirectTo: "/web1/home", pathMatch: "full" },
      {
        path: "web1",
        loadChildren: () =>
          import("./web1/web.module").then((m) => m.WebModule),
      },
    ],
  },
  /* {
    path: "",
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/play/game", pathMatch: "full" },
      {
        path: "play",
        canActivate: [AuthGuard],
        data: {
          role: Role.SuperAdministrator,
        },
        loadChildren: () =>
          import("./play/play.module").then((m) => m.PlayModule),
      },
    ],
  }, */
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },
      {
        path: "admin",
        canActivate: [AuthGuard],
        data: {
          role: Role.SuperAdministrator,
        },
        loadChildren: () =>
          import("./admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "doctor",
        canActivate: [AuthGuard],
        data: {
          role: Role.Administrator,
        },
        loadChildren: () =>
          import("./doctor/doctor.module").then((m) => m.DoctorModule),
      },
      {
        path: "teacher",
        canActivate: [AuthGuard],
        data: {
          role: Role.Teacher,
        },
        loadChildren: () =>
          import("./teacher/teacher.module").then((m) => m.TeacherModule),
      },
      {
        path: "administrator",
        canActivate: [AuthGuard],
        data: {
          role: Role.Administrator,
        },
        loadChildren: () =>
          import("./administrator/administrator-routing.module").then((m) => m.AdministratorRoutingModule),
      },
      {
        path: "superstockist",
        canActivate: [AuthGuard],
        data: {
          role: Role.SuperStockist,
        },
        loadChildren: () =>
          import("./Superstockist/superstockist.module").then((m) => m.SuperstockistModule),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./settings/settings.module").then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: "businessintelligence",
        loadChildren: () =>
          import("./businessintelligence/businessintelligence.module").then(
            (m) => m.BusinessintelligenceModule
          ),
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./reports/reports.module").then(
            (m) => m.ReportsModule
          ),
      },
      {
        path: "documents",
        loadChildren: () =>
          import("./documents/documents.module").then(
            (m) => m.DocumentsModule
          ),
      },
      {
        path: "approvals",
        loadChildren: () =>
          import("./approvals/approvals.module").then(
            (m) => m.ApprovalsModule
          ),
      },
      {
        path: "user",
        loadChildren: () =>
          import("./user/user.module").then(
            (m) => m.UserModule
          ),
      },
      {
        path: "approval",
        loadChildren: () =>
          import("./approval/approval.module").then(
            (m) => m.ApprovalModule
          ),
      },
      {
        path: "game",
        loadChildren: () =>
          import("./game/game.module").then(
            (m) => m.GameModule
          ),
      },
      {
        path: "newsevent",
        loadChildren: () =>
          import("./Newsevent/newsevent.module").then(
            (m) => m.NewseventModule
          ),
      },
      {
        path: "customer_vendor",
        loadChildren: () =>
          import("./customer_vendor/customervendor.module").then(
            (m) => m.CustomervendorModule
          ),
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("./inventory/inventory.module").then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: "quotations",
        loadChildren: () =>
          import("./quotations/quotations.module").then(
            (m) => m.QuotationsModule
          ),
      },
      {
        path: "purchase_sales",
        loadChildren: () =>
          import("./purchase_sales/purchasesales.module").then(
            (m) => m.PurchasesalesModule
          ),
      },
      {
        path: "manufacturing",
        loadChildren: () =>
          import("./manufacturing/manufacturing.module").then(
            (m) => m.ManufacturingModule
          ),
      },
      {
        path: "mrp",
        loadChildren: () =>
          import("./mrp/mrp.module").then(
            (m) => m.MrpModule
          ),
      },
      {
        path: "outsourcing",
        loadChildren: () =>
          import("./outsourcing/outsourcing.module").then(
            (m) => m.OutsourcingModule
          ),
      },
      {
        path: "accounts",
        loadChildren: () =>
          import("./accounts/accounts.module").then(
            (m) => m.AccountsModule
          ),
      },
      {
        path: "ewaybill",
        loadChildren: () =>
          import("./ewaybill/ewaybill.module").then(
            (m) => m.EwaybillModule
          ),
      },
      {
        path: "stockist",
        canActivate: [AuthGuard],
        data: {
          role: Role.Stockist,
        },
        loadChildren: () =>
          import("./stockist/stockist.module").then((m) => m.stockistModule),
      },
      {
        path: "plr",
        canActivate: [AuthGuard],
        data: {
          role: Role.Plr,
        },
        loadChildren: () =>
          import("./plr/plr.module").then((m) => m.PlrModule),
      },
      {
        path: "student",
        canActivate: [AuthGuard],
        data: {
          role: Role.Student,
        },
        loadChildren: () =>
          import("./student/student.module").then((m) => m.StudentModule),
      },
      {
        path: "patient",
        canActivate: [AuthGuard],
        data: {
          role: "Student",
        },
        loadChildren: () =>
          import("./patient/patient.module").then((m) => m.PatientModule),
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./calendar/calendar.module").then((m) => m.CalendarsModule),
      },
      {
        path: "task",
        loadChildren: () =>
          import("./task/task.module").then((m) => m.TaskModule),
      },
      {
        path: "contacts",
        loadChildren: () =>
          import("./contacts/contacts.module").then((m) => m.ContactsModule),
      },
      {
        path: "email",
        loadChildren: () =>
          import("./email/email.module").then((m) => m.EmailModule),
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./apps/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "apps",
        loadChildren: () =>
          import("./apps/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "widget",
        loadChildren: () =>
          import("./widget/widget.module").then((m) => m.WidgetModule),
      },
      {
        path: "ui",
        loadChildren: () => import("./ui/ui.module").then((m) => m.UiModule),
      },
      {
        path: "forms",
        loadChildren: () =>
          import("./forms/forms.module").then((m) => m.FormModule),
      },
      {
        path: "tables",
        loadChildren: () =>
          import("./tables/tables.module").then((m) => m.TablesModule),
      },
      {
        path: "media",
        loadChildren: () =>
          import("./media/media.module").then((m) => m.MediaModule),
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./charts/charts.module").then((m) => m.ChartsModule),
      },
      {
        path: "timeline",
        loadChildren: () =>
          import("./timeline/timeline.module").then((m) => m.TimelineModule),
      },
      {
        path: "icons",
        loadChildren: () =>
          import("./icons/icons.module").then((m) => m.IconsModule),
      },
      {
        path: "extra-pages",
        loadChildren: () =>
          import("./extra-pages/extra-pages.module").then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: "maps",
        loadChildren: () =>
          import("./maps/maps.module").then((m) => m.MapsModule),
      },
      {
        path: "multilevel",
        loadChildren: () =>
          import("./multilevel/multilevel.module").then(
            (m) => m.MultilevelModule
          ),
      },
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
